import { HttpHeaders } from '@angular/common/http';

enum ResponseContentType {
  ArrayBuffer = 'arrayBuffer',
  Blob = 'Blob',
  Json = 'application/json',
  Text = 'text/plain',
  Pdf = 'application/pdf',
  Csv = 'csv'
}

interface RequestOptions {
  headers?: HttpHeaders | { [header: string]: string | string[] };
  body?: any;
}

export { RequestOptions, ResponseContentType };
